<template>
  <v-dialog
    ref="timeSelectorDialog"
    v-model="modal"
    :return-value.sync="formattedTimeOfDay"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="pa-0"
        v-model="formattedTimeOfDay"
        :label="label"
        prepend-icon="mdi-clock-outline"
        v-bind="attrs"
        v-on="on"
        clearable
        hide-details
        @click:clear="clearField"
      />
    </template>
    <v-time-picker
      v-if="modal"
      v-model="formattedTimeOfDay"
      full-width
      format="24hr"
    >
      <v-spacer />
      <v-btn
        text
        color="red darken-1"
        @click="modal = false"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        color="blue darken-1"
        @click="onConfirm"
      >
        {{ $t('OK') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "TimeSelectorDialog",
  props: {
    label: {
      type: String,
      default: ''
    },
    selectedTime: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      formattedTimeOfDay: null,
      modal: false
    }
  },
	beforeMount() {
		if (this.selectedTime !== null && typeof this.selectedTime !== 'undefined') {
			this.formattedTimeOfDay = (new Date(this.selectedTime)).toISOString().slice(11, 16)
		}
	},
	methods: {
    onConfirm() {
      this.$refs.timeSelectorDialog.save(this.formattedTimeOfDay)
			const [hours, minutes] = this.formattedTimeOfDay.split(':')
			const timeOfDayInMillis = +hours * 60 * 60 * 1000 + minutes * 60 * 1000
      this.$emit('timeOfDayChanged', timeOfDayInMillis)
    },
    clearField() {
      this.timeOfDay = null
      this.onConfirm()
    }
  },
  watch: {
    'selectedTime': function() {
      if(this.selectedTime === null) {
        this.timeOfDay = null
      }
    }
  }
}
</script>

<style scoped>

</style>
